import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  padding: 0 20px;
`
export const TopPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 30px;
`

export const TitlePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`

export const CategoryUl = styled.ul`
  list-style: none;
  display: grid;
  grid-gap: 30px;
  margin-top: 20px;
  margin-bottom: 60px;

  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1306px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 464px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const CategoryLi = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 20px;
  &:hover {
    -webkit-box-shadow: 1px 1px 7px 1px #ddd;
    box-shadow: 1px 1px 7px 1px #ddd;
    transition: box-shadow 0.3s linear;
  }

  img {
    width: 100%;
    max-width: 260px;
    border-radius: 8px 8px 0 0;
  }
`

export const Search = styled.div`
  background: rgba(255, 255, 255);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  padding: 10px;
`
