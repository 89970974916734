import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1306px;
  padding: 0 20px;
`

export const TopPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 30px;
`

export const TitlePage = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: normal;
  }
`

export const TitlePageImage = styled.div`
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
    margin-right: 0;
  }

  > img {
    width: 230px;
    border-radius: 8px;
  }
`

export const TitleInformation = styled.div`
  width: 100%;
  display: block;
`

export const TitleInformationTop = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: left;

  @media (max-width: 768px) {
    display: block;
  }
`

export const TitleInformationBottom = styled.div`
  width: 100%;
`

export const TitlePageName = styled.div`
  width: 100%;
  display: block;
  margin-right: 30px;
`

export const SocialMedias = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: right;
  margim-top: 10px;

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-top: 25px;
  }
`

export const Services = styled.div`
  width: 100%;
  display: block;
  margin-top: 70px;
`

export const ServicesTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
`

export const ServiceUl = styled.ul`
  list-style: none;
  display: grid;
  grid-gap: 30px;
  margin-top: 20px;

  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 464px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ServiceLi = styled.li`
  width: 100%;
  margin-bottom: 20px;
`

export const ServiceLiDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`

export const ServiceLiDivDescription = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 8px;
  max-width: 350px;
  padding: 10px;
  z-index: 9;
`

export const ServiceLiDivService = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;

  &:hover {
    ${ServiceLiDivDescription} {
      display: block;
      position: absolute;
      transition: opacity 1s;
    }
  }
`

/* &:hover {
  div {
    display: flex;
    float: left;
    position: relative;
    bottom: 11px;
    left: 11px;
    z-index: 9;
    max-width: 400px;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    transition: transform 1s;
  }
} */

export const ServiceLiDivTimes = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 5px;
`

export const Category = styled.ul`
  @media (max-width: 768px) {
    display: none;
  }
`

export const CategoryCarousel = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  max-width: 261px;

  &:hover {
    -webkit-box-shadow: 1px 1px 5px 2px #ddd;
    box-shadow: 1px 1px 5px 2px #ddd;
    transition: box-shadow 0.3s;
  }

  img {
    max-width: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }
`

export const Price = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 10px;

  > div {
    width: 100%;
    display: flex;
    padding: 0;

    h2 {
      font-size: 25px;
      font-weight: 700;
      color: #07a900;
      margin-bottom: 15px;

      span {
        margin-left: 15px;
        font-size: 20px;
        font-weight: 400px;
        text-decoration: line-through;
        color: #7b7b7b;
      }
    }
    p {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
      color: #07a900;
      margin-top: 4px;
    }
  }
`

export const ActivePassport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Contact = styled.div`
  width: 100%;
  display: block;
`

export const ContactTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
`

export const ContactInformation = styled.div`
  margin-top: 25px;
  a {
    display: flex;
    align-items: center;
    justify-content: left;
  }
`

export const Video = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;
  justify-content: center;
`

export const Regulation = styled.div`
  width: 100%;
  margin-top: 70px;
`

export const RegulationTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
`

export const RegulationDescription = styled.div`
  width: 100%;
  margin-top: 25px;
`

export const Address = styled.div`
  width: 100%;
  margin-top: 70px;
`

export const AddressInformation = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`

export const GoogleMaps = styled.div`
  width: 100%;
`
