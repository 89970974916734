import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton, Backdrop, Fade } from '@mui/material'
import parse from 'html-react-parser'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
// import { toast } from 'react-toastify'
import Youtube from 'react-player/youtube'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { FaCheckCircle, FaUserCheck } from 'react-icons/fa'
import { IoMdPin } from 'react-icons/io'
import { RiUserVoiceLine } from 'react-icons/ri'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { PiUserCircleLight } from 'react-icons/pi'

import responsive from './breakpoints'

// Context
import { ErrorContext } from '../../context/ErrorContext'
// import { useAuth } from '../../context/AuthContext'

// Form
import ProfessionalServiceDetails from '../../components/ProfessionalServiceDetails'

// Services
import api from '../../services/api'
// import api_maps from '../../services/api_maps'

// Utils

// styles
import {
  Container,
  Content,
  Services,
  ServicesTitle,
  ServiceUl,
  ServiceLi,
  ServiceLiDiv,
  /* ServiceLiDivDescription, */
  ServiceLiDivService,
  /* ServiceLiDivTimes,
  ActivePassport, */
  Contact,
  ContactTitle,
  Video,
  Regulation,
  RegulationTitle,
  RegulationDescription,
  Address,
  AddressInformation,
  GoogleMaps,
  TitlePage,
  TitleInformation,
  TitleInformationTop,
  TitleInformationBottom,
  TitlePageImage,
  TitlePageName,
  SocialMedias,
  Category,
  CategoryCarousel,
  Price,
} from './styles'

import {
  TitleH1,
  TitleH2,
  /* TitleH3, */
  TitleH4,
  TitleH6,
  SeparationLine,
  Paragraph,
  Buttons,
  Button,
} from '../../styles/global'

import { CssModalForm, CssBoxModalForm } from '../../styles/globalMaterialUi'

/* interface IOrderLayers {
  id: string
  order_number: number
  transaction_id: string
  transaction_token: string
  transaction_description: string
  tid: string
  authorization_code: string
  subtotal: number
  discount: number
  total: number
  commission: number
  closing_date: Date
  ip: string
  pix_description: string
  pix_qrcode: string
  pix_key: string
  created_at: string
  cardtransactionstatus: {
    id: string
    status: string
    description: string
    authorization: boolean
  }
  passport: {
    id: string
    name: string
    price: number
    validate: number
  }
}

interface IOrder {
  order: IOrderLayers
} */

/* interface IService {
  service: {
    id: string
    customers_id: string
    professionals_id: string
    professionals_services_timetables_id: string
  }
} */

interface IProfessionalServicesImagesLayers {
  id: string
  file: {
    file_url: string
  }
}

interface IProfessionalServicesLayers {
  id: string
  name: string
  brief_description: string
  description: string
  price: number
  discount: number
  discount_formated: number
  price_discount: number
  professionalserviceimage: IProfessionalServicesImagesLayers[]
}

interface IProfessionalSocialMediasLayers {
  id: string
  icon: string
  link: string
}

interface IProfessionalImagesLayers {
  id: string
  file: {
    file_url: string
  }
}

interface IProfessionalAddressesLayers {
  id: string
  address: string
  number: string
  complement: string
  district: string
  postalcode: string
  city: string
  state: string
}

interface IProfessionalLayers {
  id: string
  name: string
  name_establishment: string
  focus_on: string
  cellphone: string
  email: string
  description: string
  regulation: string
  file: {
    file_url: string
  }
  professionaladdress: IProfessionalAddressesLayers[]
  professionalservice: IProfessionalServicesLayers[]
  professionalsocialmedia: IProfessionalSocialMediasLayers[]
  professionalimage: IProfessionalImagesLayers[]
  file_url: string
  file_url_professional: string
  address_format: string
  number_format: string
  complement_format: string
  district_format: string
  postalcode_format: string
  city_format: string
  state_format: string
}

interface IProfessional {
  professional: IProfessionalLayers
}

interface IParamTypes {
  id: string
}

const Professional: React.FC = () => {
  const { id } = useParams<IParamTypes>()

  const { ErrorMessage } = useContext(ErrorContext)
  // const {  token,  customer } = useAuth()

  // State
  const [professional, setProfessional] = useState<IProfessionalLayers>(
    {} as IProfessionalLayers,
  )
  const [professionalServiceId, setProfessionalServiceId] = useState<string>('')
  const [linkWhatsapp, setLinkWhatsapp] = useState<string>('')
  // const [order, setOrder] = useState<IOrderLayers>({} as IOrderLayers)
  const [open, setOpen] = React.useState(false)

  /*   useEffect(() => {
    async function loadOrder() {
      try {
        if (customer) {
          const response = await api.get<IOrder>(
            `/account/orders/activelast/show/${customer.id}`,
          )

          setOrder(response.data.order)
        }
      } catch (err) {
        ErrorMessage(err)
      }
    }

    loadOrder()
  }, [customer, ErrorMessage]) */

  useEffect(() => {
    async function loadProfessional() {
      try {
        if (id) {
          const response = await api.get<IProfessional>(
            `/professional/show/${id}`,
          )

          const data = {
            ...response.data.professional,
            professionalservice:
              response.data.professional.professionalservice.map((ps) => ({
                ...ps,
                discount_formated: Math.round(ps.discount),
                price_discount: ps.price - (ps.price * ps.discount) / 100,
              })),
            file_url: response.data.professional.professionalimage.length
              ? response.data.professional.professionalimage[0].file
                ? response.data.professional.professionalimage[0].file.file_url
                : ''
              : '',
            file_url_professional: response.data.professional.file
              ? response.data.professional.file.file_url
              : '',
            address_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].address
                : '',
            number_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].number
                : '',
            complement_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].complement
                : '',
            district_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].district
                : '',
            postalcode_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].postalcode
                : '',
            city_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].city
                : '',
            state_format:
              response.data.professional.professionaladdress.length > 0
                ? response.data.professional.professionaladdress[0].state
                : '',
          }

          setProfessional(data)

          const str = response.data.professional.professionalsocialmedia
            .filter((psm) => {
              return psm.icon === 'Whatsapp'
            })
            .map((p) => {
              return p.link
            })

          setLinkWhatsapp(str ? str[0] : '')

          /*           const api_address = data.professionaladdress
            ? `${data.professionaladdress[0].address} ${data.professionaladdress[0].number}, ${data.professionaladdress[0].city}, ${data.professionaladdress[0].state}, ${data.professionaladdress[0].postalcode}`
            : ''

          const data_maps = await api_maps.get(
            `?key=AIzaSyD00vFr7TfU3mf39LIvY0yXISLwv4x8NEY&address=${encodeURI(
              api_address,
            )}`,
          )

          console.log(data_maps.data.results[0].geometry.location) */
        }
      } catch (err) {
        ErrorMessage(err)
      }
    }

    loadProfessional()
  }, [ErrorMessage, id])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD00vFr7TfU3mf39LIvY0yXISLwv4x8NEY',
  })

  const handleClickWhatsapp = useCallback(
    (url: string /* , icon: string */) => {
      // if (customer && order && icon === 'Whatsapp') {
      window.open(url)
      /* } else if (icon !== 'Whatsapp') {
        window.open(url)
      } else {
        toast.info(
          'Compre o passaporte para falar e agendar com o profissional.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      } */
    },
    [
      /* customer, order */
    ],
  )

  /*   const handleActivePass = useCallback(
    async (
      customers_id: string,
      professionals_id: string,
      professionals_services_id: string,
      modality: string,
    ) => {
      if (!token) {
        toast.error('Faça o login para ativar o cupom.', {
          position: toast.POSITION.TOP_CENTER,
        })
      } else if (customer) {
        try {
          const responseUse = await api.get<IService>(
            `/account/services/serviceuse/show/${customers_id}/${professionals_id}/${professionals_services_id}`,
          )

          if (responseUse.data.service) {
            toast.error('Este cupom já foi ativado.', {
              position: toast.POSITION.TOP_CENTER,
            })
          } else {
            const response = await api.post<IService>('/account/services', {
              customers_id,
              professionals_id,
              professionals_services_id,
              modality,
            })

            if (response.data) {
              toast.success(
                'Cupom ativado com sucesso. Entre em contato com o profissional para fazer o agendamento do serviço.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              )
            }
          }
        } catch (err: any) {
          ErrorMessage(err)
        }
      }
    },
    [token, customer, ErrorMessage],
  ) */

  const handleOpen = useCallback((id: string) => {
    setOpen(true)
    console.log('id', id)
    setProfessionalServiceId(id)
  }, [])

  const handleClose = () => setOpen(false)

  return (
    <Container>
      <CssModalForm
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <CssBoxModalForm>
            {open ? (
              <ProfessionalServiceDetails id={professionalServiceId} />
            ) : null}
          </CssBoxModalForm>
        </Fade>
      </CssModalForm>

      {/* <div
        draggable="false"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: '50% 0',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(https://uploadyoutwo.s3.amazonaws.com/slide01.png)`,
          width: '100%',
          height: '100%',
        }}
        // onClick={() => console.log('click')}
      /> */}
      <img
        src={professional.file_url}
        alt={professional.name}
        title={professional.name}
      />
      <Content>
        <TitlePage>
          <TitleInformation>
            <TitleInformationTop>
              <TitlePageName>
                <TitleH1
                  color="#bfd400"
                  colorhover="#bfd400"
                  margintop="0"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                >
                  {professional.name}
                </TitleH1>
                <TitleH4
                  color="#fff"
                  colorhover="#fff"
                  margintop="5"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                  texttransform="uppercase"
                >
                  {professional.focus_on}
                </TitleH4>
                <TitleH6
                  color="#fff"
                  colorhover="#fff"
                  margintop="5"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                >
                  {professional.name_establishment}
                </TitleH6>
              </TitlePageName>
            </TitleInformationTop>
          </TitleInformation>
        </TitlePage>

        <Services>
          <ServicesTitle>
            <PiUserCircleLight color="#bfd400" size="60" />

            <TitleH2
              color="#bfd400"
              colorhover="#bfd400"
              margintop="0"
              marginbottom="0"
              marginleft="20"
              marginright="0"
            >
              SERVIÇOS OFERECIDOS
            </TitleH2>
          </ServicesTitle>
          <ServiceUl>
            {professional.professionalservice
              ? professional.professionalservice.map((ps) => (
                  <ServiceLi key={ps.id}>
                    <ServiceLiDiv>
                      <ServiceLiDivService onClick={() => handleOpen(ps.id)}>
                        <IconButton size="small" aria-label="Serviço">
                          <FaCheckCircle color="#bfd400" size="24" />
                        </IconButton>
                        <Paragraph
                          color="#fff"
                          colorhover="#fff"
                          margintop="0"
                          marginbottom="0"
                          marginleft="10"
                          marginright="10"
                        >
                          {ps.name}
                        </Paragraph>
                      </ServiceLiDivService>

                      <Category>
                        <Carousel responsive={responsive}>
                          {ps.professionalserviceimage &&
                            ps.professionalserviceimage.map((psi) => (
                              <CategoryCarousel key={psi.id}>
                                <div
                                  style={{
                                    position: 'absolute',
                                    padding: '7px',
                                    margin: '1px',
                                    background: '#bfd400',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    fontSize: '1.2rem',
                                    width: '45px',
                                    height: '45px',
                                  }}
                                >
                                  {ps.discount_formated}% <br /> off
                                </div>
                                <img
                                  src={psi.file ? psi.file.file_url : ''}
                                  alt={ps.name}
                                  title={ps.name}
                                />
                              </CategoryCarousel>
                            ))}
                        </Carousel>
                      </Category>
                    </ServiceLiDiv>
                    <ServiceLiDiv>
                      <Paragraph
                        color="#fff"
                        colorhover="#fff"
                        margintop="0"
                        marginbottom="0"
                        marginleft="10"
                        marginright="10"
                      >
                        {ps.brief_description}
                      </Paragraph>
                    </ServiceLiDiv>
                    <ServiceLiDiv>
                      <Price>
                        <div>
                          <p>R$</p>

                          {ps.discount ? (
                            <h2>
                              {ps.price_discount.toFixed(2).replace('.', ',')}
                              <span>
                                {ps.price.toString().replace('.', ',')}
                              </span>
                            </h2>
                          ) : (
                            <h2>
                              {ps.price
                                ? ps.price.toString().replace('.', ',')
                                : '0'}
                            </h2>
                          )}
                        </div>
                      </Price>
                    </ServiceLiDiv>
                  </ServiceLi>
                ))
              : ''}
          </ServiceUl>

          <Buttons margintop="10" align="center">
            <Button
              background="#bfd400"
              texttransform="uppercase"
              type="button"
              onClick={() =>
                handleClickWhatsapp(linkWhatsapp /* , 'Whatsapp' */)
              }
            >
              <Contact>
                <ContactTitle>
                  <RiUserVoiceLine color="#fff" size="40" />

                  <TitleH6
                    color="#fff"
                    colorhover="#fff"
                    margintop="0"
                    marginbottom="0"
                    marginleft="20"
                    marginright="0"
                    fontweight="800"
                  >
                    FALE OU AGENDE COM O PROFISSIONAL
                  </TitleH6>
                </ContactTitle>
              </Contact>
            </Button>
          </Buttons>
        </Services>

        <SeparationLine colorline="#C7C7C7" margintop="50" />

        <TitlePage>
          <TitleInformation>
            <TitleInformationBottom>
              <ServicesTitle>
                <FaUserCheck color="#bfd400" size="60" />

                <TitleH2
                  color="#bfd400"
                  colorhover="#bfd400"
                  margintop="0"
                  marginbottom="0"
                  marginleft="25"
                  marginright="10"
                >
                  SOBRE NÓS
                </TitleH2>
              </ServicesTitle>

              <Paragraph
                color="#fff"
                colorhover="#fff"
                margintop="25"
                marginleft="0"
                marginright="25"
              >
                {professional.description
                  ? parse(professional.description)
                  : ''}
              </Paragraph>
            </TitleInformationBottom>
          </TitleInformation>
          <TitlePageImage>
            <img
              src={professional.file_url_professional}
              alt={professional.name}
              title={professional.name}
            />

            <SocialMedias>
              {professional.professionalsocialmedia
                ? professional.professionalsocialmedia.map((psm) => (
                    <IconButton
                      key={psm.id}
                      size="small"
                      aria-label="Serviço"
                      onClick={() =>
                        handleClickWhatsapp(psm.link /* , psm.icon */)
                      }
                    >
                      {psm.icon === 'Instagram' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-instagram.png"
                          alt="Instagram"
                          title="Instagram"
                        />
                      ) : psm.icon === 'Facebook' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-facebook.png"
                          alt="Facebook"
                          title="Facebook"
                        />
                      ) : psm.icon === 'Google' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-google.png"
                          alt="Google"
                          title="Google"
                        />
                      ) : psm.icon === 'Linkedin' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-linkedin.png"
                          alt="Linkedin"
                          title="Linkedin"
                        />
                      ) : psm.icon === 'Pinterest' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-pinterest.png"
                          alt="Pinterest"
                          title="Pinterest"
                        />
                      ) : psm.icon === 'Snapchat' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-snapchat.png"
                          alt="Snapchat"
                          title="Snapchat"
                        />
                      ) : psm.icon === 'Tiktok' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-tiktok.png"
                          alt="Tiktok"
                          title="Tiktok"
                        />
                      ) : psm.icon === 'X' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-x.png"
                          alt="X"
                          title="X"
                        />
                      ) : psm.icon === 'Whatsapp' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-whatsapp.png"
                          alt="Whatsapp"
                          title="Whatsapp"
                        />
                      ) : psm.icon === 'Youtube' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-youtube.png"
                          alt="Youtube"
                          title="Youtube"
                        />
                      ) : psm.icon === 'Site' ? (
                        <img
                          style={{ width: '40px' }}
                          src="https://uploadyoutwo.s3.us-east-1.amazonaws.com/icon-site.png"
                          alt="Site"
                          title="Site"
                        />
                      ) : (
                        ''
                      )}
                    </IconButton>
                  ))
                : ''}
            </SocialMedias>
          </TitlePageImage>
        </TitlePage>

        <Video>
          <Youtube url="https://www.youtube.com/shorts/MMIln0g2HU8" />
        </Video>

        <Regulation>
          <RegulationTitle>
            <IoDocumentTextOutline color="#bfd400" size="60" />

            <TitleH2
              color="#bfd400"
              colorhover="#bfd400"
              margintop="0"
              marginbottom="0"
              marginleft="20"
              marginright="0"
            >
              Regulamento
            </TitleH2>
          </RegulationTitle>

          <RegulationDescription>
            <Paragraph color="#fff" colorhover="#fff">
              {professional.regulation ? parse(professional.regulation) : ''}
            </Paragraph>
          </RegulationDescription>
        </Regulation>

        {professional.professionaladdress ? (
          professional.professionaladdress.length > 0 ? (
            <Address>
              <GoogleMaps>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={{ lat: 27, lng: 28 }}
                    zoom={15}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <></>
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </GoogleMaps>
              <AddressInformation>
                <div>
                  <IoMdPin color="#CFCFCF" size="36" />
                </div>
                <div>
                  <Paragraph
                    color="#fff"
                    colorhover="#fff"
                    margintop="0"
                    marginbottom="0"
                    marginleft="10"
                    marginright="10"
                  >
                    {`${professional.address_format}, ${professional.number_format} ${professional.complement_format} - Bairro ${professional.district_format}`}
                  </Paragraph>
                  <Paragraph
                    color="#fff"
                    colorhover="#fff"
                    margintop="0"
                    marginbottom="0"
                    marginleft="10"
                    marginright="10"
                  >
                    {`CEP ${professional.postalcode_format}, - ${professional.city_format}/${professional.state_format}`}
                  </Paragraph>
                </div>
              </AddressInformation>
            </Address>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </Content>
    </Container>
  )
}

export default Professional
