import React, { useEffect, useState, useCallback, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'

import { MdSearch, MdClear } from 'react-icons/md'

// Context
import { ErrorContext } from '../../../context/ErrorContext'

// Services
import api from '../../../services/api'

// Utils

// styles
import { Container, Content, CategoryUl, CategoryLi, Search } from './styles'
import {
  TitleH1,
  TitleH3,
  TitleH6,
  TitleH5,
  Paragraph,
  Buttons,
  Button,
  UlFormCol1,
} from '../../../styles/global'
import { CssTextField } from '../../../styles/globalMaterialUi'

interface IValuesForm {
  search_name: string
}

interface ICategoryLayers {
  id: string
  name: string
  status: boolean
}

interface ICategory {
  status: boolean
  category: ICategoryLayers
}

interface IProfessionalsLayers {
  id: string
  name: string
  name_establishment: string
  focus_on: string
  file: {
    file_url: string
  }
}

interface IProfessionals {
  categoriesprofessionals: IProfessionalsLayers[]
}

interface IParamTypes {
  id: string
}

const Sub1Categories: React.FC = () => {
  const { id } = useParams<IParamTypes>()

  const { ErrorMessage } = useContext(ErrorContext)

  // State
  const [professionals, setProfessionals] = useState<IProfessionalsLayers[]>([])
  const [category, setCategory] = useState<ICategoryLayers>(
    {} as ICategoryLayers,
  )
  const [search, setSearch] = useState<IValuesForm>({
    search_name: '',
  })

  useEffect(() => {
    async function loadCategory() {
      try {
        if (id) {
          const response = await api.get<ICategory>(`/category/show/${id}`)

          setCategory(response.data.category)
        }
      } catch (err) {
        ErrorMessage(err)
      }
    }

    loadCategory()
  }, [ErrorMessage, id])

  useEffect(() => {
    async function loadProfessionals() {
      try {
        const response = await api.get<IProfessionals>('/category', {
          params: {
            search_categories_id: id,
            search_name: search.search_name,
          },
        })

        setProfessionals(response.data.categoriesprofessionals)
      } catch (err) {
        ErrorMessage(err)
      }
    }

    loadProfessionals()
  }, [id, search, ErrorMessage])

  const handleSearch = useCallback(
    async (values: IValuesForm) => {
      try {
        if (values) {
          setSearch(values)
        }
      } catch (err) {
        ErrorMessage(err)
      }
    },
    [ErrorMessage],
  )

  return (
    <Container>
      <Content>
        <TitleH1
          color="#bfd400"
          colorhover="#bfd400"
          margintop="50"
          marginleft="10"
          marginright="10"
        >
          {category.name}
        </TitleH1>

        <Formik
          initialValues={{
            search_name: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            await handleSearch(values)

            setSubmitting(false)
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Search>
                <UlFormCol1>
                  <li>
                    <CssTextField
                      id="input-search-name"
                      label="Pesquisar"
                      variant="outlined"
                      name="search_name"
                      size="small"
                      value={values.search_name}
                      autoComplete="search_name"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </li>
                </UlFormCol1>

                <Buttons margintop="20" align="center">
                  <Button
                    paddingleft="15"
                    paddingright="15"
                    marginright="15"
                    background="#2CAA09"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <MdSearch
                          color="#fff"
                          size="18"
                          style={{ marginRight: '10px' }}
                        />
                        <Paragraph color="#fff" colorhover="#fff">
                          PESQUISANDO...
                        </Paragraph>
                      </>
                    ) : (
                      <>
                        <MdSearch
                          color="#fff"
                          size="18"
                          style={{ marginRight: '10px' }}
                        />
                        <Paragraph color="#fff" colorhover="#fff">
                          PESQUISAR
                        </Paragraph>
                      </>
                    )}
                  </Button>
                  <Button
                    paddingleft="15"
                    paddingright="15"
                    marginright="15"
                    background="#3d3d3d"
                    type="reset"
                  >
                    <MdClear
                      color="#fff"
                      size="18"
                      style={{ marginRight: '10px' }}
                    />
                    <Paragraph color="#fff" colorhover="#fff">
                      LIMPAR
                    </Paragraph>
                  </Button>
                </Buttons>
              </Search>
            </Form>
          )}
        </Formik>

        <CategoryUl>
          {professionals.map((p) => (
            <CategoryLi key={p.id}>
              <Link to={`/professional/${p.id}`} style={{ fontSize: '0' }}>
                <img
                  src={p.file ? p.file.file_url : ''}
                  alt={p.name}
                  title={p.name}
                />
                <TitleH3
                  color="#bfd400"
                  colorhover="#bfd400"
                  margintop="10"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                  fontsize="2rem"
                >
                  {p.name}
                </TitleH3>
                <TitleH5
                  color="#fff"
                  colorhover="#fff"
                  margintop="5"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                  texttransform="uppercase"
                  fontsize="1.8rem"
                >
                  {p.focus_on}
                </TitleH5>
                <TitleH6
                  color="#fff"
                  colorhover="#fff"
                  margintop="5"
                  marginbottom="0"
                  marginleft="10"
                  marginright="10"
                  fontsize="1.6rem"
                >
                  {p.name_establishment}
                </TitleH6>
              </Link>
            </CategoryLi>
          ))}
        </CategoryUl>
      </Content>
    </Container>
  )
}

export default Sub1Categories
