import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`

export const TitlePage = styled.div`
  display: flex;
`

export const Services = styled.div`
  width: 100%;
  display: grid;
  margin-top: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ServicesImages = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ServicesDescriptions = styled.div`
  width: 100%;
  display: block;
`

export const Category = styled.ul`
  @media (max-width: 768px) {
    display: none;
  }
`

export const CategoryCarousel = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  max-width: 261px;

  &:hover {
    -webkit-box-shadow: 1px 1px 5px 2px #ddd;
    box-shadow: 1px 1px 5px 2px #ddd;
    transition: box-shadow 0.3s;
  }

  img {
    max-width: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }
`

export const Price = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 10px;

  > div {
    width: 100%;
    display: flex;
    padding: 0;

    h2 {
      font-size: 25px;
      font-weight: 700;
      color: #07a900;
      margin-bottom: 15px;

      span {
        margin-left: 15px;
        font-size: 20px;
        font-weight: 400px;
        text-decoration: line-through;
        color: #7b7b7b;
      }
    }
    p {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
      color: #07a900;
      margin-top: 4px;
    }
  }
`
