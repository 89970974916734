import React from 'react'
import { styled } from '@mui/material/styles'
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  TextareaAutosize,
  Checkbox,
  CheckboxProps,
  RadioGroup,
  Radio,
  RadioProps,
  Tooltip,
  AppBar,
  Tabs,
  Tab,
  TabProps,
  Box,
  Toolbar,
  Table,
  TableSortLabel,
  TableHead,
  TableCell,
  Modal,
  Fade,
  Paper,
  IconButton,
  List,
  ListItemText,
  Step /*
  LinearProgress,
  Modal,
  Fade, */,
} from '@mui/material'
// import { Pagination } from '@material-ui/lab';

/* Form Labels */
export const CssFormControl = styled(FormControl)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export const CssFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiTypography-body1': {
    fontSize: '1.6rem',
  },
}))

export const CssFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-body1': {
    fontSize: '1.6rem',
  },
}))

/* Layers */
export const CssTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: '12px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '1.6rem',
  },
  '& label.Mui-focused': {
    color: '#201e1e',
  },
  '& legend': {
    fontSize: '1.2rem',
  },
  '& .MuiInputBase-root': {
    fontSize: '1.6rem',
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.text.secondary,
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 10px)',
  },
  '& .MuiSvgIcon-root': {
    width: '1.6rem',
    height: '1.6rem',
    fontSize: '1.6rem',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#201e1e',
    },
    '&:hover fieldset': {
      boxShadow: '2px 2px 5px 1px #dddddd',
      borderColor: '#201e1e',
      transition: 'boxShadow 0.2s',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#201e1e',
    },
  },
  '& .MuiFormHelperText-root': {
    fontSize: '1.2rem',
  },
}))

export const CssTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  marginTop: '12px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '1.6rem',
  },
  '& label.Mui-focused': {
    color: '#201e1e',
  },
  '& legend': {
    fontSize: '1.2rem',
  },
  '& .MuiInputBase-root': {
    fontSize: '1.6rem',
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.text.secondary,
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 10px)',
  },
  '& .MuiSvgIcon-root': {
    width: '1.6rem',
    height: '1.6rem',
    fontSize: '1.6rem',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#201e1e',
    },
    '&:hover fieldset': {
      boxShadow: '2px 2px 5px 1px #dddddd',
      borderColor: '#201e1e',
      transition: 'boxShadow 0.2s',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#201e1e',
    },
  },
  '& .MuiFormHelperText-root': {
    fontSize: '1.2rem',
  },
}))

export const CssCheckBox = styled((props: CheckboxProps) => (
  <Checkbox color="default" {...props} />
))(() => ({
  marginLeft: '10px',
  color: '#3d3d3d',
  height: '100%',
  alignItems: 'center',
  '&$checked': {
    color: '#201e1e',
  },
  '& .MuiSvgIcon-root': {
    width: '2rem',
    height: '2rem',
  },
}))

export const CssRadioGroup = styled(RadioGroup)(() => ({
  color: '#fff',
  height: '100%',
  alignItems: 'center',
  '& .MuiTypography-body1': {
    fontSize: '1.6rem',
  },
}))

export const CssRadio = styled((props: RadioProps) => (
  <Radio color="default" {...props} />
))(() => ({
  marginLeft: '10px',
  color: '#fff',
  '&$checked': {
    color: '#201e1e',
  },
}))

/* Tooltip Customizado */
export const CssTooltip = styled(Tooltip)(() => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: 220,
    fontSize: '1.4rem !important',
  },
}))

/* Table */
export const CssToolbar = styled(Toolbar)(() => ({
  width: '100%',
  padding: '16px !important',
}))

export const CssTable = styled(Table)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[200],
}))

export const CssTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  fontSize: '1.4rem',
}))

export const CssTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
}))

export const CssTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.6rem !important',
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 12,
  paddingRight: 12,
}))

/* Surface */
export const CssPaper = styled(Paper)(() => ({
  width: '100%',
  boxShadow:
    '2px 1px 8px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important',
}))

/* Layout */
export const CssBox = styled(Box)(() => ({
  width: '100%',
}))

export const CssBoxToolBarFilter = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

/* Styles Geral Tabs */

export const CssAppBar = styled(AppBar)(() => ({
  boxShadow: '0px 9px 9px -6px rgba(0,0,0,0.12)',
  transform: 'none',
}))

export const CssTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '8px 8px 0 0',
}))

export const CssTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 72,
  fontWeight: 400,
  fontSize: '1.6rem',
  marginRight: '10px',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.text.primary,
    opacity: 1,
  },
  '&$selected': {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  '&:focus': {
    color: theme.palette.text.primary,
  },
}))

/* Styles Modal */
export const CssModalForm = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CssBoxModalForm = styled(Box)(({ theme }) => ({
  position: 'relative' as const,
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.grey[700]}`,
  boxShadow: theme.shadows[5],
  margin: '15px',
  overflowY: 'scroll',
  maxHeight: '90vh',
  maxWidth: '1306px',
  width: '100%',
}))

export const CssFadeModalForm = styled(Fade)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'scroll',
  top: '5%',
  maxHeight: '90vh',
  maxWidth: '1306px',
  width: '100%',
}))

export const CssWithoutBorder = styled(Modal)(({ theme }) => ({
  modal: {
    display: 'flex',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'scroll',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100%',
    minHeight: '100vh',
  },
}))

/* Icon */
export const CssIconButtonTableHeader = styled(IconButton)(({ theme }) => ({
  borderRadius: '8px !important',
  fontSize: '1.6rem',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
}))

/* List */
export const CssListMenu = styled(List)(() => ({
  width: '280px',
}))

export const CssListItemTextMenu = styled(ListItemText)(({ theme }) => ({
  fontSize: '1.6rem',
  '.MuiListItemText-primary': {
    fontSize: '1.6rem',
    color: theme.palette.text.primary,
  },
  '.MuiListItemText-secondary': {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
  },
}))

export const CssListSearchProduct = styled(List)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  maxHeight: '300px',
  overflow: 'auto',
  border: `1px ${theme.palette.grey[700]} solid`,
  borderRadius: '8px',
  background: theme.palette.common.white,
  zIndex: 2,
}))

export const CssListItemTextSearchProduct = styled(ListItemText)(
  ({ theme }) => ({
    fontSize: '1.4rem',
    '.MuiListItemText-primary': {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: theme.palette.info.main,
    },
    '.MuiListItemText-secondary': {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: theme.palette.warning.main,
    },
  }),
)

/* Step */
export const CssStep = styled(Step)(() => ({
  '.MuiSvgIcon-root': {
    width: '4rem',
    height: '4rem',
  },
  '.MuiStepLabel-label': {
    fontSize: '1.6rem',
  },
  '.MuiStepLabel-label.Mui-active': {
    color: '#fff',
  },
  '.MuiStepLabel-label.Mui-completed': {
    color: '#fff',
  },
}))
