import React from 'react'

// Components
/* import ContentTop from '../ContentTop'
import ContentBottom from '../ContentBottom' */

// Styles
import { Container } from './styles'

const Home: React.FC = () => {
  return (
    <Container>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingTop: '56.2225%',
          paddingBottom: 0,
          boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
          marginTop: '0',
          marginBottom: '0.9em',
          overflow: 'hidden',
          borderRadius: '8px',
          willChange: 'transform',
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            border: 'none',
            padding: 0,
            margin: 0,
          }}
          src="https://www.canva.com/design/DAGXq_9iwUQ/qSuhnlxqxXMR1jSIF_t1hg/view?embed"
          allowFullScreen={true}
          allow="fullscreen"
        />
      </div>
      {/* <ContentTop />
      <ContentBottom /> */}
    </Container>
  )
}

export default Home
