import React, { useEffect, useState, useContext } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import responsive from './breakpoints'

// Context
import { ErrorContext } from '../../context/ErrorContext'
// import { useAuth } from '../../context/AuthContext'

// Services
import api from '../../services/api'
// import api_maps from '../../services/api_maps'

// Utils

// styles
import {
  Container,
  Services,
  ServicesImages,
  ServicesDescriptions,
  TitlePage,
  /* Category, */
  CategoryCarousel,
  Price,
} from './styles'
import { TitleH1, Paragraph } from '../../styles/global'

interface IProfessionalServicesImagesLayers {
  id: string
  file: {
    file_url: string
  }
}

interface IProfessionalServiceLayers {
  id: string
  name: string
  brief_description: string
  description: string
  price: number
  discount: number
  discount_formated: number
  price_discount: number
  professionalserviceimage: IProfessionalServicesImagesLayers[]
}

interface IProfessionalService {
  professionalservice: IProfessionalServiceLayers
}

interface IParamProps {
  id: string
}

const ProfessionalServiceDetails: React.FC<IParamProps> = ({ id }) => {
  const { ErrorMessage } = useContext(ErrorContext)

  // State
  const [professionalService, setProfessionalService] =
    useState<IProfessionalServiceLayers>({} as IProfessionalServiceLayers)

  useEffect(() => {
    async function loadProfessionalService() {
      try {
        if (id) {
          const response = await api.get<IProfessionalService>(
            `/professionalservice/show/${id}`,
          )

          const data = {
            ...response.data.professionalservice,
            discount_formated: Math.round(
              response.data.professionalservice.discount,
            ),
            price_discount:
              response.data.professionalservice.price -
              (response.data.professionalservice.price *
                response.data.professionalservice.discount) /
                100,
          }

          setProfessionalService(data)
        }
      } catch (err) {
        ErrorMessage(err)
      }
    }

    loadProfessionalService()
  }, [ErrorMessage, id])

  return (
    <Container>
      <TitlePage>
        <TitleH1
          color="#bfd400"
          colorhover="#bfd400"
          margintop="0"
          marginbottom="0"
          marginleft="10"
          marginright="10"
        >
          {professionalService.name}
        </TitleH1>
      </TitlePage>

      <Services>
        <ServicesImages>
          <Carousel responsive={responsive}>
            {professionalService.professionalserviceimage
              ? professionalService.professionalserviceimage.map((psi) => (
                  <CategoryCarousel key={psi.id}>
                    <div
                      style={{
                        position: 'absolute',
                        padding: '7px',
                        margin: '1px',
                        background: '#bfd400',
                        borderRadius: '50%',
                        textAlign: 'center',
                        fontSize: '1.2rem',
                        width: '45px',
                        height: '45px',
                      }}
                    >
                      {professionalService.discount_formated}% <br /> off
                    </div>
                    <img
                      src={psi.file ? psi.file.file_url : ''}
                      alt={professionalService.name}
                      title={professionalService.name}
                    />
                  </CategoryCarousel>
                ))
              : ''}
          </Carousel>
        </ServicesImages>
        <ServicesDescriptions>
          <div>
            <Paragraph
              color="#191919"
              colorhover="#191919"
              margintop="0"
              marginbottom="0"
              marginleft="10"
              marginright="10"
            >
              {professionalService.description}
            </Paragraph>
          </div>
          <Price>
            <div>
              <p>R$</p>

              {professionalService.discount ? (
                <h2>
                  {professionalService.price_discount
                    .toFixed(2)
                    .replace('.', ',')}
                  <span>
                    {professionalService.price.toString().replace('.', ',')}
                  </span>
                </h2>
              ) : (
                <h2>
                  {professionalService.price
                    ? professionalService.price.toString().replace('.', ',')
                    : '0'}
                </h2>
              )}
            </div>
          </Price>
        </ServicesDescriptions>
      </Services>
    </Container>
  )
}

export default ProfessionalServiceDetails
