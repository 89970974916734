import axios from 'axios'

const token = localStorage.getItem('@YoutwoWeb:token')

const api = axios.create({
  baseURL: 'https://youtwo.azurewebsites.net/web',
  // baseURL: 'http://localhost:8080/web',
  // headers: { Authorization: `Bearer ${token}` },
})

api.defaults.headers.common.Authorization = `Bearer ${token}`

export default api
